import React from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/template.css';
import '../../styles/about.css';
import '../../styles/team.css';
import Layout from '../../components/Layout';

const Page = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy - General terms & conditions</title>
      </Helmet>
      <div>
        <main id="terms-conditions">
          <h1
            style={{
              textAlign: 'center',
              marginTop: '100px',
              fontSize: '35px',
              marginBottom: '20px',
              fontWeight: 'bold',
            }}
          >
            General terms & conditions
          </h1>

          <section className="content-section">
            <div
              className="page-content privay-policy"
              style={{ paddingTop: 30, flexDirection: 'column' }}
            >
              <p>General terms & conditions of use for the Racqy services.</p>
              <br />
              <p>
                The general terms of use (“Terms of Use”) are available on the
                Racqy websites. Terms of Use apply to all services, all usage,
                and all content offered, sold, and/or made available on any
                Racqy channel such as website, mobile app, and so forth (“the
                Racqy Channels”).
              </p>
              <br />
              <p>
                Unless otherwise stated about a specific country, the Racqy
                Channels and services are provided by Racqy AB, 559306-9668
                (“Racqy AB”). Racqy reserves the right to take legal actions in
                all cases of dissemination, reproduction, or usage of these
                Terms of Use, for other than personal use, unless written
                consent from Racqy has been obtained.{' '}
              </p>
              <br />
              <p>
                The Racqy Channels and services are offered on the condition
                that you accept the Terms of Use without reservation. This also
                applies to terms that appear on other pages of the website. Your
                use of any of the Racqy Channels confirms this agreement and all
                its points and conditions.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Changes in terms of use
              </h3>
              <p>
                Racqy reserves the right to change the terms of use without
                notice
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              ></h3>
              Racqy’s commitments
              <p>
                Racqy will provide Racqyng and booking services online through
                the use of the Internet and electronic devices.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                User commitments
              </h3>
              <p>
                Information that is submitted or sent to Racqy must be honest
                and correct. This applies, for example, to your name.
              </p>
              <br />
              <p>
                By using the website and its services you agree to abide by all
                laws and to respect all third parties and their rights, as well
                as these Terms of Use.
              </p>
              <br />
              <p>
                To best enjoy the Racqy Channels and to maintain a comfortable
                and safe environment, you pledge to:
              </p>
              <br />
              <p>
                Act in a correct manner towards Racqy and other users of the
                website
              </p>
              <br />
              <p>
                Photos and other information in the form of data or files that
                you provide should be decent and exclusively about you or
                another person who has granted you permission for usage.
              </p>
              <br />
              <p>
                Only use your credentials to gain access to the website for
                yourself.
              </p>
              <br />
              <p>
                You may not disclose, divulge or make your user information
                available to third parties.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                Links to other websites
              </h3>
              <p>
                The website can contain links to other websites (“Links”). These
                websites are not controlled by Racqy and therefore Racqy takes
                no responsibility for the content on these websites. This
                applies, without restriction, to all links to external websites.
                Also, Racqy is not responsible for any transfer of data from the
                external links referred to on the website. This applies, without
                restriction, to all forms of transfer. Racqy solely offers these
                links as a service to the user. The occurence of these links
                does not necessarily mean that Racqy sympathizes with the
                perceptions that these websites or their operators display.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Copyright and trademark information
              </h3>
              <p>
                All content on the site is Copyright 2021-2022 by Racqy and/or
                their partners. All rights reserved. You may not in any way
                publish, distribute or reproduce the material, trademarks,
                images, or other intellectual property rights of third-party
                proprietary information in any form, commercial or private,
                without first obtaining permission from the owners of the work
                or the rights holders.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                Abuse and illegal use
              </h3>
              <p>
                By using the Racqy Channels, you affirm and guarantee that you
                will not use the Racqy Channels in such a manner that would go
                against Swedish legislation or the terms set out on this page.
                You will not under any circumstances use the Racqy Channels in a
                way that may injure, deactivate, overload, destroy, or in any
                other way negatively affect it or other users’ visits to the
                Racqy Channels. You will not acquire or try to acquire access to
                material that you did not receive written permission for through
                the Racqy Channels.
              </p>
              <br />
              <p>
                You accept that: you will not in any way violate the security of
                others. You will not publish, upload or distribute any form of
                harmful or inappropriate material or information. You will not
                upload files or other material that is protected by copyright
                laws without owning said rights or having the explicit
                permission of the proprietor. You will not advertise, promote,
                or in any way attempt to sell goods or services. You will not
                perform surveys, convey chain letters, or organize pyramid
                schemes. You will not download any file that, by your knowledge
                or not, can not be distributed with respect to copyright laws.
                You will not book or purchase resources or offers on the Racqy
                Channels using, for example, web scraping solutions or other
                automated solutions. You will also not use the Racqy Channels
                with any commercial intent, including, but not limited to,
                different marketing activities, booking and reselling times or
                offers to other individuals or third parties, etcetera, unless
                you have a written agreement for doing so with Racqy.
              </p>
              <br />
              <p>
                Racqy has no obligation to monitor all services on the website.
                However, Racqy reserves the right to evaluate all material
                posted on the website. Racqy also reserves the right to exclude
                or terminate access to any material and remove such material
                without notice.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Termination/access restriction
              </h3>
              <p>
                Racqy reserves the right to terminate your access to the website
                and its related services without notice. You as a visitor, user,
                or member of this site hereby accept these conditions. Violation
                of these conditions may result in exclusion from the site and,
                in the worst case, lawsuits under Swedish and international
                legislation.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Reservations and liability waiver
              </h3>
              <p>
                Under no circumstances is Racqy liable for direct or indirect
                injuries suffered by you as a user, where you have contributed
                to the injury.
              </p>
              <br />
              <p>
                To the extent deemed possible under applicable law, Racqy waives
                all commitments, guarantees, terms, or other conditions
                (including but not limited to guarantees concerning quality or
                suitability, either expressed or implied) related to the
                website.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                General Data Protection Regulation (GDPR)
              </h3>
              <p>
                Racqy handles the buyer’s personal data in accordance with GDPR
                (General Data Protection Regulation) and never sends them to
                non-Racqy parties. The only exception is whether we explicitly
                request your approval at the time of purchase.
              </p>
              <br />
              <p>Read more about our privacy policy here.</p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Applicable law
              </h3>
              <p>
                These terms of use and disputes arising from these or use of the
                website shall be regulated and interpreted according to Swedish
                law. Any disputes will be resolved by the Swedish general court.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                About us
              </h3>
              <p>
                The services are provided på Racqy AB, organisation number
                559306-9668. Birger Jarlsgatan 2, 114 34, Stockholm
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Terms & conditions
              </h3>
              <p>Last update 2022-06-16</p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Prices
              </h3>
              <p>All prices listed include VAT.</p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Seller
              </h3>
              <p>
                Connected partners/venues (sellers) sell services, primarily
                bookings, through Racqy (intermediary).
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Purchase
              </h3>
              <p>
                Purchases can only be made via the website. We do not accept
                purchases made via telephone, fax, or email. We reserve the
                right to log the client's IP number at purchase.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Card information
              </h3>
              <p>
                Your card information is managed by our partner Adyen, a
                PCI-certified payment service. All transactions that go through
                Adyen are encrypted with the highest level of security and Racqy
                never sees the customer's card information.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Payment
              </h3>
              <p>
                Payments may be made by debit or credit cards from Visa,
                MasterCard, or American Express. You can also pay with one a
                digital punch card provided by Racqy. No extra fees apply.
                Payment is made to Racqy or a party designated by Racqy. In
                Switzerland, such a designated party is Racqy GmbH. You will
                receive information about payment options on the booking page.
                Cancellations with refunds are generally possible up until 6
                hours before the booked time slot, but certain conditions
                specified at the time of purchase may apply. When canceling a
                booking paid for using a debit or credit card, by default you
                would be charged a cancellation fee of an amount specified
                during booking and cancellation.
              </p>
              <br />
              <p>
                You must be at least 18 years of age to complete a purchase
                unless otherwise specified.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Right of withdrawal
              </h3>
              <p>Limited time offer</p>
              <br />
              <p>
                According to the Law on distance and doorstep selling (3§ SFS
                2005:59), there is no right of withdrawal on this type of
                service, but most purchases still have the option of being
                canceled up until six hours before the subject time of delivery
                and purchase of the service.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                Abuse
              </h3>
              <p>
                If the service is misused we reserve the right to terminate the
                user. In this case, all outstanding benefits owed to the user
                expire as well.
              </p>
              <h3
                style={{
                  marginTop: '50px',
                  marginBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Right of usage for services purchased
              </h3>
              <p>
                Purchased services may only be used in their entirety on a
                single occasion. It is not possible to redeem the service,
                wholly or in part, in cash or to save it for future use. The
                purchase is personal and may not be shared with others unless
                otherwise specified. Please read the terms and conditions for
                each specific purchase.
              </p>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default Page;
